<template>
  <b-card no-body>

    <div class="m-1">

      <!-- Table Top -->
      <b-row
        align-h="end"
        class="font-weight-bolder"
      >
        <b-col>
          Documents
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="4"
        >

          <b-form-input
            v-model="filter"
            class="d-inline-block mr-1"
            type="search"
            placeholder="Search..."
          />

        </b-col>
      </b-row>

    </div>

    <b-table
      striped
      hover
      responsive
      show-empty
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="media"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >

      <template #cell(actions)="data">

        <div class="text-nowrap">
          <!-- <feather-icon
            :id="`invoice-row-${data.item.id}-download-icon`"
            icon="ArrowDownCircleIcon"
            class="cursor-pointer"
            size="16"
            @click="downloadDocument(data.item)"
          />
          <b-tooltip
            title="Download Document"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-download-icon`"
          /> -->

          <feather-icon
            :id="`invoice-row-${data.item.id}-open-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="goToDocument(data.item)"
          />
          <b-tooltip
            title="Open Document"
            :target="`invoice-row-${data.item.id}-open-icon`"
          />
        </div>
      </template>

    </b-table>

    <!-- page length -->

    <!-- pagination -->
    <div class="d-flex justify-content-between flex-wrap  ml-1 mr-1 text-right">
      <div class="d-flex mb-0 ">
        <!-- <span class="align-items-center mr-1 text-nowrap">
          <small class="align-items-center">
            Per Page
          </small>
        </span>
        <b-form-select
          v-model="perPage"
          :options="pageOptions"
          size="sm"
          @input="(value)=>({currentPerPage:value})"
        /> -->

      </div>
      <div class="mr-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>

        </b-pagination>
      </div>
    </div>
  </b-card>
</template>

<script>

import {
  BTable, BFormInput, BCol, BRow, BCard, BTooltip, BPagination,

} from 'bootstrap-vue'
// import axios from '@axios'`

export default {
  name: 'DocumentsTable',
  components: {

    BTable,
    BTooltip,
    BCard,

    // BFormSelect,
    BPagination,

    BFormInput,

    BCol,
    BRow,

  },
  props: {
    media: {
      type: Array,
      default() { return [] },
      required: false,
    },

  },
  data() {
    return {
      perPage: 5,
      pageOptions: [5, 10, 15],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [

        {
          key: 'type', label: 'Type', sortable: true,
        },
        { key: 'name', label: 'Document', sortable: true },
        'Actions',

      ],

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.media ? this.media.length : 0
  },
  methods: {
    // log(value) {
    //   console.log(value)
    // },
    // ToDo: Need to enable CORS in backend to be able to download with axios
    // downloadDocument(item) {
    //   axios({
    //     url: item.originUrl, // your url
    //     method: 'GET',
    //     responseType: 'blob', // important
    //   }).then(response => {
    //     const url = window.URL.createObjectURL(new Blob([response.data]))
    //     const link = document.createElement('a')
    //     link.append('Access-Control-Allow-Credentials', 'true')
    //     link.href = url
    //     link.setAttribute('download', 'file.jpg') // or any other extension
    //     document.body.appendChild(link)

    //     link.click()
    //   })
    // },
    goToDocument(item) {
      window.open(item.originUrl)
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
