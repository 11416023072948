<template>
  <b-card
    no-body
    class="card-apply-job"
  >
    <b-card-header class="pb-1">
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar
            size="42"
            :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')"
          />
        </b-media-aside>
        <b-media-body>
          <h5 class="mb-0">
            Alira Evans
          </h5>
          <font>
            Project Manager
          </font>
        </b-media-body>
      </b-media>

    </b-card-header>
    <b-card-body>
      <h5 class="apply-job-title mt-1">
        Contact the property manager
      </h5>

      <b-card-text class="mt-2">
        <span class="font-weight-bolder">
          Phone:
        </span>
        03 9684 8150
        <b-card-text />
        <b-card-text>
          <span class="font-weight-bolder">
            Sales Office:
          </span> 342-350 Centre Rd, Berwick VIC 3806

        </b-card-text>

        <b-card-text>
          <span class="font-weight-bolder">
            Email:
          </span> alira@evas.com

        </b-card-text>

      </b-card-text>
      <b-row>
        <b-col class="mt-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="outline-primary"
          >

            <feather-icon
              icon="MailIcon"
            />

            Contact
          </b-button>
        </b-col>
        <b-col class="mt-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
          >

            <feather-icon
              icon="MessageCircleIcon"
            />

            Chat
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BMedia, BMediaAside, BAvatar, BMediaBody, BCardText, BCardBody, BButton, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,

    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,

  },
  directives: {
    Ripple,
  },
}
</script>
