<template>
  <section>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="product === undefined"
    >

      <div class="alert-body">
        Error fetching product data. Check
        <b-link
          class="alert-link"
          :to="{ name: 'inventory'}"
        >
          Search
        </b-link>
        for other items.

      </div>
    </b-alert>

    <!-- Content -->

    <template
      v-if="product"
    >

      <b-row align-h="center">
        <b-col
          cols="12"
          xl="3"
          lg="8"
          md="7"
        >
          <b-card
            no-body
            class="card-payment"
          >

            <!-- about -->
            <b-img
              v-if="product.lotPlanUrl"
              :class=" $store.state.appConfig.layout.skin === 'light' ? 'card-img-top colour-change-img-white': 'card-img-top colour-change-img-black'"
              :src="product.lotPlanUrl"

              height="300"
            />
            <b-img
              v-if="!product.lotPlanUrl"
              alt="Lot Image"
              height="300"

              :src="require(`@/assets/images/lots/Lot1.jpg`)"
            />

            <h3 class="text-center mb-1">
              Lot {{ product.name }}
            </h3>
            <h6 class="text-center mb-1">
              {{ product.project.name }}
            </h6>
            <b-row align-h="center">
              <b-badge
                class="text-center"
                variant="success"
              >
                Available
              </b-badge>
            </b-row>

            <hr class="mt-2 ml-2 mr-2">
            <!-- ToDo: Frontage wraps under size and depth when you reduce the size. -->

            <div class="d-flex justify-content-between align-items-center mr-2 ml-2 mb-2">

              <div>
                <h6 class="text-muted font-weight-bolder text-center">
                  Size
                </h6>
                <h4 class="mb-0 text-center">
                  {{ product.size }}m<sup>2</sup>
                </h4>
              </div>

              <div>
                <h6 class="text-muted font-weight-bolder text-center">
                  Depth
                </h6>
                <h4 class="mb-0 text-center">
                  {{ product.depth }}m<sup>2</sup>
                </h4>
              </div>

              <div>
                <h6 class="text-muted font-weight-bolder text-center">
                  Frontage
                </h6>
                <h4 class="mb-0 text-center">
                  {{ product.frontage }}m<sup>2</sup>
                </h4>
              </div>

            </div>

            <!-- ToDo: The two icons have a big gap between eachother when you reduce the size. -->

            <!-- <b-row class="pl-2 pt-2">
              <b-col class="p-1">
                <b-media
                  no-body
                >
                  <b-media-aside>
                    <b-avatar
                      rounded
                      variant="light-primary"
                      size="34"
                    >
                      <feather-icon
                        icon="MapPinIcon"
                        size="18"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="mb-0">
                      Melbourne?
                    </h6>
                    <small>Location</small>
                  </b-media-body>

                </b-media>
              </b-col>

              <b-col class="p-1">
                <b-media
                  v-if="product.titled"
                  no-body
                >
                  <b-media-aside>
                    <b-avatar
                      style="object-fit:contain"
                      rounded
                      variant="light-primary"
                      size="34"
                    >
                      <feather-icon
                        icon="ClipboardIcon"
                        size="18"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="mb-0">
                      {{ product.titled }}
                    </h6>
                    <small>Titled</small>
                  </b-media-body>

                </b-media>
              </b-col>

            </b-row> -->

          </b-card>

          <b-card
            no-body
            class="border-primary"
          >
            <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
              <h4 class="mb-0">
                <b-badge variant="light-primary">
                  Price
                </b-badge>
              </h4>

              <h2 class="text-primary">
                ${{ new Intl.NumberFormat( { style: 'currency', }).format(product.price) }}
              </h2>

              <small class="text-muted w-100"> as of July 22, 2021?</small>
            </b-card-header>

            <b-card-body>
              <!-- <ul class="list-unstyled my-1">
                <li>
                  <span class="align-middle">5 Users</span>
                </li>
                <li class="my-25">
                  <span class="align-middle">10 GB storage</span>
                </li>
                <li>
                  <span class="align-middle">Basic Support</span>
                </li>
              </ul> -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                class="mt-2"
              >
                Reserve Now
              </b-button>
            </b-card-body>
          </b-card>
          <contact-card />

        </b-col>

        <b-col
          cols="12"
          xl="5"
          lg="8"
          md="7"
        >
          <!-- {{ log(product) }} -->

          <b-card
            :img-src="product.project.heroURL"
            img-height="200"
            fluid
            class="card-profile-modified"
          >
            <div class="profile-image-wrapper">
              <div class="profile-image p-0">
                <b-avatar
                  size="114"
                  variant="light"
                  :src="product.project.logoURL"
                />
              </div>
            </div>

            <b-row class="pt-5">
              <b-col
                cols="3"
                class=" pr-0"
              >
                <font class="text-muted font-weight-bolder">
                  Developer
                </font>
              </b-col>
              <b-col
                cols="4"
                class="pl-0 font-weight-bolder pr-0"
              >
                <h6>

                  {{ product.project.vendorName }}
                </h6>
              </b-col>

              <b-col
                cols="2"
                class="pl-0 pr-0"
              >
                <font class="text-muted font-weight-bolder pl-0 pr-0">

                  Street
                </font>
              </b-col>

              <b-col

                class="pl-0 pr-0 font-weight-bolder"
              >
                <h6>
                  {{ product.street }}
                </h6>
              </b-col>

            </b-row>

            <b-row class="pt-2">
              <b-col
                cols="3"
                class="pr-0"
              >
                <font class="text-muted font-weight-bolder">
                  Stage
                </font>

              </b-col>

              <b-col
                cols="4"
                class="pl-0 pr-0 font-weight-bolder"
              >
                <h6>

                  {{ product.stage }}
                </h6>
              </b-col>

              <b-col
                cols="2"
                class="pr-0 pl-0"
              >
                <font class="text-muted font-weight-bolder">
                  Title
                </font>
              </b-col>

              <b-col

                class="pl-0 pr-0 font-weight-bolder"
              >
                <h6>

                  {{ product.titled || product.titleExpected }}
                </h6>
              </b-col>

            </b-row>

            <b-row class="pt-2">
              <b-col
                cols="3"
                class="pr-0"
              >
                <font class="text-muted font-weight-bolder">
                  City
                </font>

              </b-col>

              <b-col
                cols="4"
                class="pl-0 font-weight-bolder"
              >
                <h6>
                  {{ product.city }}
                </h6>
              </b-col>

              <b-col
                cols="2"
                class="pl-0 pr-0"
              >
                <font class="text-muted font-weight-bolder pl-0">
                  Postcode
                </font>
              </b-col>

              <b-col

                class="pl-0 pr-0 font-weight-bolder"
              >
                <h6>

                  {{ product.postcode }}
                </h6>
              </b-col>

            </b-row>

          </b-card>

          <b-row>
            <b-col>

              <documents-table :media="product.project.media" />
              <b-row>
                <b-col>
                  <statistic-card-vertical
                    icon="TrendingUpIcon"
                    statistic="12k"
                    statistic-title="Views"
                    color="info"
                  />
                </b-col>

                <b-col>
                  <statistic-card-vertical
                    color="primary"
                    icon="EyeIcon"
                    statistic="26"
                    statistic-title="Watching"
                  />
                </b-col>

              </b-row>

            </b-col>
          </b-row>
        </b-col>

      </b-row>

    </template>

  </section>
</template>

<script>

import { ref } from '@vue/composition-api'
import {
  BCard, BRow, BCol, BCardText, BLink, BAlert, BCardHeader, BBadge, BCardBody, BButton, BAvatar, BContainer, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { API } from 'aws-amplify'
import * as queries from '@/libs/graphql/queries'
import router from '@/router'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { useInventoryUi } from '../useInventory'
import DocumentsTable from './DocumentsTable.vue'
import ContactCard from './ContactCard.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    // eslint-disable-next-line vue/no-unused-components
    BContainer,
    BCard,
    // BMedia,
    // BMediaAside,
    // BMediaBody,
    BImg,
    StatisticCardVertical,

    BRow,
    BCol,
    BCardHeader,
    BBadge,
    BCardBody,
    BButton,

    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BLink,

    BAlert,
    BAvatar,

    DocumentsTable,
    ContactCard,

  },
  setup() {
    const { handleCartActionClick, toggleProductInWishlist } = useInventoryUi()

    const product = ref(null)

    // I am performing an appsync query and if i get a result I set the product variable with the result data. But if the query returns an error I set product to undefined and that casues the alert to show up. To access the slug (this.$router) I had to import router
    // eslint-disable-next-line func-names
    API.graphql({ query: queries.getProduct, variables: { id: router.history.router.currentRoute.params.slug } })
      .then((result => { product.value = result.data.getProduct }))
      .catch(() => { product.value = undefined })

    // UI
    const selectedColor = ref(null)

    return {

      // Fetched Product
      product,

      // UI
      selectedColor,
      handleCartActionClick,
      toggleProductInWishlist,
    }
  },
  data() {
    return {
      mainProps: {
        rounded: true,
      },
      chartData: {
        series: [
          {
            name: 'Sales',
            data: [90, 50, 86, 40, 100, 20],
          },
          {
            name: 'Visit',
            data: [70, 75, 70, 76, 20, 85],
          },
        ],
      },
    }
  },

  methods: {
    // log(value) {
    //   console.log(value)
    // },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import "~@core/scss/base/pages/app-ecommerce.scss";

// .no-padding {
//   padding-right: 0
// }
        .no-overflow {

  overflow: hidden;
  text-overflow: ellipsis;

      }

.card-profile-modified{
  .card-body {
    position: relative;

    .profile-image-wrapper {
      display: flex;
      justify-content: center;

      .profile-image {
        position: absolute;
        top: -4rem;
        padding: 0.5rem;
        margin-bottom: 1.15rem;
        border-radius: 50%;
        background-color: $white;

        img {
  width: 100px;
          object-fit: contain;
          height: 100px;

        }
      }
    }

    .profile-badge {
      margin-top: 0.8rem;
      margin-bottom: 0.8rem;
    }
  }
}

  .colour-change-img-white {
     filter: invert(59%) sepia(0%) saturate(1933%) hue-rotate(151deg) brightness(85%) contrast(95%);
  }

  .colour-change-img-black {
   filter: invert(0%) sepia(0%) saturate(15%) hue-rotate(280deg) brightness(101%) contrast(104%);
  }

</style>
